import { ComboboxItem } from '@mantine/core';
import dayjs from 'dayjs';
import { Measure, TimeAllocation } from '../../api/work-periods-client/work-periods-client.type';
import { Tab, ViewType, WorkPeriodType } from '../../containers/process-analysis/process-analysis.type';
import { useProcessAnalysisStore } from './process-analysis-store';
import { MeasureUnitMap } from './process-analysis-store.type';

/**
 * Returns the view type based on the active tab and other state variables.
 *
 * @return {ViewType} The view type, which can be one of the following:
 *                    - ViewType.Comparison: when there are multiple portfolios, teams, or boards.
 *                    - ViewType.Single: when there is only one portfolio, team, or board.
 *                    - ViewType.Sprint: when there is only one board with 'sprint_performance' measure and a valid sprint ID.
 */
const useViewType = (): ViewType => {
  const { activeTab, portfolioIds, teamIds, boardIds } = useProcessAnalysisStore();

  switch (activeTab) {
    case Tab.Portfolios:
      return portfolioIds.length > 1 ? ViewType.Comparison : ViewType.Single;
    case Tab.Teams:
      return teamIds.length > 1 ? ViewType.Comparison : ViewType.Single;
    case Tab.Boards:
      return boardIds.length > 1 ? ViewType.Comparison : ViewType.Single;
    case Tab.WorkPeriods:
      return ViewType.Assessment;
    default:
      return ViewType.Comparison;
  }
};

/**
 * Returns a list of entities based on the active tab in the process analysis store.
 *
 * @return {string[]} A list of entity IDs (e.g., portfolio IDs, team IDs, or board IDs)
 */
const useEntities = (): string[] => {
  const { activeTab, portfolioIds, teamIds, boardIds } = useProcessAnalysisStore();

  switch (activeTab) {
    case Tab.Portfolios:
      return portfolioIds || [];
    case Tab.Teams:
      return teamIds || [];
    case Tab.Boards:
      return boardIds || [];
    default:
      return [];
  }
};

/**
 * Returns an object containing the start and end dates converted to Date objects.
 *
 * @return {{ startDate: Date; endDate: Date }} An object with the start and end dates.
 */
const useDateRange = (): { startDate: Date; endDate: Date } => {
  const startDate = useProcessAnalysisStore((state) => dayjs(state.startDate).toDate());
  const endDate = useProcessAnalysisStore((state) => dayjs(state.endDate).toDate());

  return { startDate, endDate };
};

/**
 * Calculates the time allocation based on the difference between the start and end dates.
 *
 * @return {TimeAllocation} The time allocation, which can be Daily, Weekly, BiWeekly, or Monthly.
 */
const useTimeAllocation = (): TimeAllocation => {
  const startDate = useProcessAnalysisStore((state) => dayjs(state.startDate));
  const endDate = useProcessAnalysisStore((state) => dayjs(state.endDate));

  const diff = endDate.diff(startDate, 'day') + 1;

  switch (true) {
    case diff < 25:
      return TimeAllocation.Daily;
    case diff < 51:
      return TimeAllocation.Weekly;
    case diff < 101:
      return TimeAllocation.BiWeekly;
    default:
      return TimeAllocation.Monthly;
  }
};

/**
 * Returns the start and end dates of the work period.
 * If the work period is of type "Defined", it returns the start and end dates of the defined work period.
 * If the work period is of type "Custom", it returns the start and end dates of the custom date range.
 *
 * @returns {{ startDate: dayjs.Dayjs; endDate: dayjs.Dayjs }} An object with the start and end dates of the work period.
 */
const useWorkPeriodDates = (): { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } => {
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const workPeriod = useProcessAnalysisStore((state) => state.workPeriod);
  const startDate = useProcessAnalysisStore((state) => dayjs(state.startDate));
  const endDate = useProcessAnalysisStore((state) => dayjs(state.endDate));

  if (workPeriodType === WorkPeriodType.Defined && workPeriod) {
    return { startDate: dayjs(workPeriod.start_date), endDate: dayjs(workPeriod.end_date) };
  }

  return { startDate, endDate };
};

/**
 * Returns the length of the work period in days.
 * If the work period is of type "Custom", it calculates the length by subtracting the start date from the end date.
 * If the work period is of type "Defined", it calculates the length by subtracting the start date of the defined work period from its end date.
 *
 * @returns {number} The length of the work period in days.
 */
const useWorkPeriodLength = (): number => {
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const workPeriod = useProcessAnalysisStore((state) => state.workPeriod);
  const { startDate, endDate } = useDateRange();

  if (workPeriodType === WorkPeriodType.Custom) {
    return dayjs(endDate).diff(dayjs(startDate), 'day') + 1;
  }

  if (!workPeriod) {
    return 0;
  }

  return dayjs(workPeriod.end_date).diff(dayjs(workPeriod.start_date), 'day') + 1;
};

/**
 * Returns an array of combobox items representing each day of the selected work period.
 * Each item has a value, label, disabled state, and a date associated with it.
 * The value is the day number, the label is the day number and date in the format 'DD/MM',
 * the disabled state is true if the date is after the current date, and the date is the
 * date of the day in the selected work period.
 *
 * @returns {ComboboxItem[]} An array of combobox items representing each day of the selected work period.
 */
const useWorkPeriodDayOptions = (): ComboboxItem[] => {
  const workPeriodLength = useWorkPeriodLength();
  const { startDate } = useWorkPeriodDates();
  const now = dayjs();

  return Array.from({ length: workPeriodLength }).map((_, index) => {
    const date = startDate.add(index, 'day');
    const disabled = date.isAfter(now);

    return { value: `${index + 1}`, label: `${index + 1} (${date.format('MM/DD')})`, disabled, date };
  });
};

/**
 * Returns the last valid day of the selected work period. If there are no valid days, it returns null.
 *
 * @returns {string | null} The last valid day of the selected work period, or null if there are no valid days.
 */
const useDefaultDay = (): string | null => {
  const dayOptions = useWorkPeriodDayOptions();

  const validDayOptions = dayOptions.filter((option) => !option.disabled);

  if (validDayOptions.length === 0) {
    return null;
  }

  return validDayOptions[validDayOptions.length - 1].value;
};

/**
 * Returns a boolean indicating whether the selected work period is of type "Defined".
 * This is used to determine whether to use historical_burns measure.
 *
 * @returns {boolean} True if the selected work period is of type "Defined", false otherwise.
 */
const useHistoricalBurns = (): boolean => {
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);

  return workPeriodType === WorkPeriodType.Defined;
};

/**
 * Returns an array of measure names based on the availableMeasures array in the store.
 *
 * @returns {Measure[]} An array of measure names.
 */
const useAvailableMeasureNames = (): Measure[] => {
  const availableMeasures = useProcessAnalysisStore((state) => state.availableMeasures);

  return availableMeasures.map(({ measure_name }) => measure_name as Measure);
};

/**
 * Returns an array of combobox items representing the available measures in the store.
 * Each item has a value and label, where the value is the measure name and the label is the measure title.
 *
 * @returns {ComboboxItem[]} An array of combobox items representing the available measures.
 */
const useAvailableMeasureOptions = (): ComboboxItem[] => {
  const availableMeasures = useProcessAnalysisStore((state) => state.availableMeasures);

  return availableMeasures.map(({ measure_name, measure_title }) => ({ value: measure_name, label: measure_title }));
};

/**
 * Returns an object with the measure names as keys and the measure units as values.
 *
 * @returns {{ [key: string]: string }} An object with the measure names as keys and the measure units as values.
 */
const useAvailableMeasureUnits = (): MeasureUnitMap => {
  const availableMeasures = useProcessAnalysisStore((state) => state.availableMeasures);

  return availableMeasures.reduce<MeasureUnitMap>((acc, curr) => {
    acc[curr.measure_name] = curr.measure_units;
    return acc;
  }, {} as MeasureUnitMap);
};

export {
  useAvailableMeasureNames,
  useAvailableMeasureOptions,
  useAvailableMeasureUnits,
  useDateRange,
  useDefaultDay,
  useEntities,
  useHistoricalBurns,
  useTimeAllocation,
  useViewType,
  useWorkPeriodDates,
  useWorkPeriodDayOptions,
  useWorkPeriodLength,
};
