import { styled } from '@linaria/react';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { usePortfolioProjects } from '../../api/summary-client/summary-client.hooks';
import { Measure } from '../../api/work-periods-client/work-periods-client.type';
import { formatTasks } from '../../components/financials-tasks/financials-tasks.helpers';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { useGlobalStore } from '../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../store/process-analysis-store/process-analysis-store';
import { useDateRange } from '../../store/process-analysis-store/process-analysis-store.hooks';
import { SubtitleHeadingTag, TitleHeadingTag } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { TasksTable } from '../tasks-table/tasks-table';
import { TaskRecord } from '../tasks-table/tasks-table.type';
import { useTasksData } from './assessment-view/assessment-view.hooks';
import { formatTitle, mapSubprojectIdsToNames } from './process-analysis-tasks.helpers';
import { WorkPeriodType } from './process-analysis.type';
import { useWorkPeriodForm } from './scope-explorer/scope-explorer.hooks';

export const ProcessAnalysisTasks = () => {
  const [searchParams] = useSearchParams();
  const measure = searchParams.get('measure');
  const date = searchParams.get('date') || '';
  const factor = searchParams.get('factor') || 'ready';
  const { workPeriodType, workPeriod } = useProcessAnalysisStore((state) => ({
    workPeriodType: state.workPeriodType,
    workPeriod: state.workPeriod,
  }));
  const { startDate, endDate } = useDateRange();
  const currentPortfolio = useGlobalStore((state) => state.portfolio);
  const { projects: teams = [] } = usePortfolioProjects(currentPortfolio?.id, {
    enabled: Boolean(currentPortfolio?.id),
  });
  const idNameMap = mapSubprojectIdsToNames(teams);
  const { workPeriodForm } = useWorkPeriodForm();
  const currentBoardId = workPeriodForm.getValues().workPeriodBoardId;
  const currentBoardName = idNameMap[currentBoardId as string];
  const formattedStartDate = dayjs(startDate).format('MM/DD/YYYY');
  const formattedEndDate = dayjs(endDate).format('MM/DD/YYYY');
  const { data: tasksQueryData = [], isFetching } = useTasksData(measure as Measure);

  const showLocalError = !isFetching && !tasksQueryData.length;
  const titleMeasure = measure === Measure.FlowByPhase ? factor : measure || '';

  return (
    <MenuDrivenContainer contentStyles={{ height: '100%', boxSizing: 'border-box' }}>
      <BrandedLoadingOverlay visible={isFetching} transitionDuration={30} variant="colored" />
      <TasksContainer>
        <HeaderContainer>
          <TitleHeadingTag>{formatTitle(titleMeasure, workPeriodType, workPeriod, currentBoardName)}</TitleHeadingTag>
          <SubtitleHeadingTag>
            {measure !== Measure.FlowByPhase ? (
              <>
                {workPeriodType === WorkPeriodType.Defined
                  ? `${dayjs(workPeriod?.start_date).format('MM/DD/YYYY')} - ${dayjs(workPeriod?.end_date).format(
                      'MM/DD/YYYY'
                    )}`
                  : `${formattedStartDate} - ${formattedEndDate}`}
              </>
            ) : (
              `Date: ${date}`
            )}
          </SubtitleHeadingTag>
        </HeaderContainer>
        {showLocalError && <PageHeaderMessage message="There are no related tasks for this selection." color="red" />}
        <TasksTable tasks={formatTasks(tasksQueryData as TaskRecord[])} />
      </TasksContainer>
    </MenuDrivenContainer>
  );
};

const HeaderContainer = styled.div`
  align-items: center;
`;

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 16px;
  padding-left: 16px;
`;
