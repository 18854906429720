import { WorkPeriodTense } from '../../../api/work-periods-client/work-periods-client.type';
import { formatDate } from '../../../helpers/timezone/timezone';
import { DateTemplate } from '../../../helpers/timezone/timezone.type';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  CurrentSprintMark,
  DatePeriod,
} from '../../../styles/new-shared-styled-components/new-shared-styled-components';

export function DateAndSprintDisplay() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const workPeriods = useProcessAnalysisStore((state) => state.workPeriods);

  // Get the selected work period
  const workPeriodId = useProcessAnalysisStore((state) => state.workPeriod?.id);
  const selectedWorkPeriod = workPeriods.find((wp) => wp.id === workPeriodId);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DatePeriod>
          {formatDate(selectedWorkPeriod?.start_date ?? '', timezone, DateTemplate.MMDDYYYY)}
          {' to '}
          {formatDate(selectedWorkPeriod?.end_date ?? '', timezone, DateTemplate.MMDDYYYY)}
        </DatePeriod>

        {selectedWorkPeriod && selectedWorkPeriod.time_tense === WorkPeriodTense.Present && (
          <CurrentSprintMark style={{ marginLeft: '10px' }}>CURRENT SPRINT</CurrentSprintMark>
        )}
      </div>
    </div>
  );
}
