import { ChartData, TooltipItem } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Measure, MeasureDataResponse, MeasureUnits } from '../../../api/work-periods-client/work-periods-client.type';
import { round, simpleLinearRegression } from '../../../helpers/math-helpers/math-helpers';
import { snakeCaseToTitleCase } from '../../../helpers/string-helpers/string-helpers';
import { convertAndRoundMeasureValues } from '../../../helpers/work-period-measures/convert-metrics-helpers';
import {
  useAvailableMeasureUnits,
  useTimeAllocation,
} from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { MeasureUnitMap } from '../../../store/process-analysis-store/process-analysis-store.type';
import { newCOLORS } from '../../../styles/colors';
import { createLine } from '../process-analysis-chart.helpers';
import { getChartLabels, y1AxisInUse, y1AxisLabel, yAxisInUse } from './measure-comparison.helpers';
import { MeasuresWithColor } from './measure-comparison.type';
type ProcessAnalysisMeasuresComparisonChartProps = {
  measureData: MeasureDataResponse;
  measuresWithColor: MeasuresWithColor;
  trendsWithColor: MeasuresWithColor;
};

export const ProcessAnalysisMeasuresComparisonChart = ({
  measureData,
  measuresWithColor,
  trendsWithColor,
}: ProcessAnalysisMeasuresComparisonChartProps) => {
  const measureUnitMap = useAvailableMeasureUnits();

  const measureNames = Object.keys(measuresWithColor) as Measure[];
  const trendNames = Object.keys(trendsWithColor) as Measure[];

  const timeBasedMeasures: Measure[] = [Measure.LeadTime, Measure.ReactionTime, Measure.CycleTime];

  const timeAllocation = useTimeAllocation();

  const labels = getChartLabels(measureData, timeAllocation);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    pointStyle: true,
    aspectRatio: 2.5,
    spanGaps: true,
    layout: {
      padding: {
        top: 20,
        right: 0,
      },
    },

    animation: {
      duration: 0,
    },

    scales: {
      x: {
        title: {
          display: labels.length > 0,
          text: 'Dates',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        id: 'y',
        beginAtZero: true,
        position: 'left' as const,
        suggestedMax: 100,
        title: {
          display: true,
          text: '%',
          color: yAxisInUse(measureNames, trendNames, measureUnitMap) ? undefined : newCOLORS.gray,
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
          color: yAxisInUse(measureNames, trendNames, measureUnitMap) ? undefined : newCOLORS.gray,
        },
      },
      y1: {
        id: 'y1',
        beginAtZero: true,
        position: 'right' as const,
        title: {
          display: true,
          text: y1AxisLabel(measureNames, trendNames, measureUnitMap),
          color: y1AxisInUse(measureNames, trendNames, measureUnitMap) ? undefined : newCOLORS.gray,
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
          color: y1AxisInUse(measureNames, trendNames, measureUnitMap) ? undefined : newCOLORS.gray,
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        common: {
          drawTime: 'afterDraw',
        },
      },
      filler: {
        propagate: true,
        drawTime: 'beforeDatasetsDraw' as const,
      },
      tooltip: {
        callbacks: {
          title,
          label: (tooltipItem: TooltipItem<'line'>) => label(tooltipItem, measureUnitMap),
        },
      },
    },
  };

  const formatData = (): ChartData<'line'> => {
    const chartData: ChartData<'line'> = {
      labels,
      datasets: [],
    };

    for (const measure of Object.keys(measuresWithColor) as Measure[]) {
      if (!measureData[measure]) {
        continue;
      }

      const convertedValues = convertAndRoundMeasureValues(measureData, measure, timeBasedMeasures, 1);

      chartData.datasets.push(
        createLine(
          measure,
          convertedValues,
          measuresWithColor[measure] ?? newCOLORS.black,
          false,
          Object.entries(measureUnitMap)
            .filter(([_, unit]) => unit === 'Percentage')
            .map(([name]) => name)
            .includes(measure)
            ? 'y'
            : 'y1'
        )
      );
    }

    for (const trend of Object.keys(trendsWithColor) as Measure[]) {
      if (!measureData[trend]) {
        continue;
      }

      const convertedValues = convertAndRoundMeasureValues(measureData, trend, timeBasedMeasures, 1);

      const xAxis = Array.from({ length: convertedValues.length }, (_value, index) => index);
      const line = simpleLinearRegression(xAxis, convertedValues);
      if (line) {
        const { slope, intercept } = line;
        const trendData = xAxis.map((x) => round(slope * x + intercept, 1));
        chartData.datasets.push(
          createLine(
            trend,
            trendData,
            trendsWithColor[trend] ?? newCOLORS.black,
            true,
            Object.entries(measureUnitMap)
              .filter(([_, unit]) => unit === 'Percentage')
              .map(([name]) => name)
              .includes(trend)
              ? 'y'
              : 'y1'
          )
        );
      }
    }

    return chartData;
  };

  return <Line data={formatData()} options={options} />;
};

/**
 * Custom tooltip title function that concatenates the dataset labels from the tooltip items.
 *
 * @param {TooltipItem<'line'>[]} tooltipItems - The tooltip items containing the dataset labels.
 * @returns {string} The concatenated title string.
 */
export function title(tooltipItems: TooltipItem<'line'>[]): string {
  return tooltipItems
    .map((item) => {
      const measureName = snakeCaseToTitleCase(item.dataset.label || '');
      // trend lines have a borderDash
      const isTrend = item.dataset.borderDash && item.dataset.borderDash.length;
      return isTrend ? `${measureName} (Trend)` : measureName;
    })
    .join('\n');
}

/**
 * Custom tooltip label function that appends the unit of the measure to the formatted value.
 *
 * @param {TooltipItem<'line'>} tooltipItem - The tooltip item containing the dataset label and formatted value.
 * @param {Object} measureUnitMap - An object mapping measure names to their units.
 * @returns {string} The formatted label string with the unit appended.
 */
function label(tooltipItem: TooltipItem<'line'>, measureUnitMap: MeasureUnitMap): string {
  const measureName = tooltipItem.dataset.label as Measure;
  const unit = measureName in measureUnitMap ? measureUnitMap[measureName] : '';
  const formattedValue = typeof tooltipItem.raw === 'number' ? round(tooltipItem.raw, 1) : null;

  return unit === MeasureUnits.Percentage ? `${formattedValue}%` : `${formattedValue} ${unit.toLocaleLowerCase()}`;
}
