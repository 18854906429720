import { MultiSelect, MultiSelectProps } from '@mantine/core';
import { useGlobalStore } from '../../store/global-store/global-store';

export function TeamMultiselect(props: Omit<MultiSelectProps, 'data'>) {
  const { value: teamIds, onChange: handleTeamSelected, ...rest } = props;
  const teams = useGlobalStore((state) => state.portfolioTeams);

  return (
    <MultiSelect
      {...rest}
      value={teams.length > 0 ? teamIds : []}
      data={teams.map((t) => ({ value: t.id, label: t.name }))}
      onChange={(teamIds: string[]) => handleTeamSelected?.(teamIds)}
      style={{ minWidth: 200 }}
    />
  );
}
