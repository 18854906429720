import dayjs from 'dayjs';
import { useWorkPeriodsMeasureMultiple } from '../../../api/work-periods-client/work-periods-client.hooks';
import {
  Measure,
  MeasureDataResponse,
  Transformer,
  UseWorkPeriodsMeasurePayloadMultiple,
} from '../../../api/work-periods-client/work-periods-client.type';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  useDateRange,
  useEntities,
  useTimeAllocation,
} from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { useMeasureFilter } from '../comparison-view/comparison-view.hooks';
import { getEntityType } from '../process-analysis.helpers';
import { MeasureComparisonType } from './measure-comparison.type';

/**
 * Custom hook that fetches measure comparison data.
 *
 * @param {MeasureComparisonType} type - The type of measure comparison to fetch.
 *
 * @returns {{ data: MeasureDataResponse | null; isFetching: boolean }}
 */
const useMeasureComparisonData = (
  type: MeasureComparisonType
): { data: MeasureDataResponse | null; isFetching: boolean } => {
  const entities = useEntities();
  const entity = entities[0];

  const { defaultMeasures, transformerMeasures } = useMeasureFilter();

  const measures = type === MeasureComparisonType.Default ? defaultMeasures : transformerMeasures;

  const isEnabled = measures.length > 0;
  const options = { enabled: isEnabled };

  const basePayload = usePayload(measures);

  const payload =
    type === MeasureComparisonType.Transformer
      ? { ...basePayload, transformer: Transformer.TimeAllocationValuesOnly }
      : basePayload;

  const { data, isFetching } = useWorkPeriodsMeasureMultiple(payload, options);

  const parsedData = data && entity ? data[entity] : null;
  return { data: parsedData, isFetching };
};

/**
 * Custom hook that returns a payload for fetching measure comparison data.
 *
 * @param {Measure} measures - The list of measures for the payload
 * @return {UseWorkPeriodsMeasurePayloadMultiple} - The payload for fetching work period measures.
 */
const usePayload = (measures: Measure[]): UseWorkPeriodsMeasurePayloadMultiple => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const { startDate, endDate } = useDateRange();
  const entities = useEntities();
  const timeAllocation = useTimeAllocation();

  return {
    entity: getEntityType(activeTab),
    ids: entities,
    start_date: dayjs(startDate).format('YYYY-MM-DD'),
    end_date: dayjs(endDate).format('YYYY-MM-DD'),
    measure_name: measures,
    time_allocation_type: timeAllocation,
  };
};

export { useMeasureComparisonData };
