import { Checkbox, LoadingOverlay, Select, Title } from '@mantine/core';
import isEqual from 'lodash/isEqual';
import { Fragment, useContext, useRef } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { icons } from '../../../assets/icons/icons';
import { ComboboxSelect } from '../../../components/combobox-select/combobox-select';
import { DownloadPNGButton } from '../../../components/download-png-button';
import { findClosestMantineColor } from '../../../helpers/color-helpers/color-helpers';
import { canvasHtmlDownload } from '../../../helpers/image-downloader/image-downloader';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { useDateRange, useEntities } from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { SelectContainer, SelectLabel, selectData } from '../../strategy/initiative-performance/collapsible-section';
import { RowStart, TD } from '../key-measures/key-measures-over-time';
import { Pills } from '../pills/pills';
import { ProcessAnalysisChart } from '../process-analysis-chart';
import { ProcessAnalysisContext } from '../process-analysis.context';
import {
  ITEM_COLORS,
  getPills,
  handleChangeEntity,
  handleChangeTrend,
  matchEntitiesWithColor,
} from '../process-analysis.helpers';
import { Entities, MetricType, Section } from '../process-analysis.type';
import {
  ChartContainer,
  ContentContainer,
  DeleteIcon,
  DropdownContainer,
  LegendContainer,
  LegendScrollContainer,
  LegendTable,
  NoInitiativesPlaceholder,
  StickyTH,
} from './initiative-completion';
import { useInitiativeFocusData } from './initiative-focus.hooks';
import { DropShadowContainer } from './initiative-overview';
import { isActiveInitiative, sortInitiatives } from './initiative-sections.helpers';

export function InitiativeFocus() {
  const { portfolios = [], teams = [] } = useGlobalStore();

  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const { startDate, endDate } = useDateRange();
  const entities = useEntities();

  const downloadRef = useRef<HTMLDivElement>(null);
  const exceptionRef = useRef<HTMLDivElement>(null);

  const pills = getPills(entities, activeTab, portfolios, teams, startDate, endDate);

  const handleDownload = () => canvasHtmlDownload(pills.join('_'), downloadRef, exceptionRef);

  const {
    [Section.InitiativeFocus]: {
      metric,
      initiatives,
      initiativesData,
      selectedInitiatives,
      selectedTrends,
      setMetric,
      setInitiatives,
      setInitiativesData,
      setSelectedInitiatives,
      setSelectedTrends,
    },
  } = useContext(ProcessAnalysisContext);

  const { data: initiativeFocus, isFetching } = useInitiativeFocusData();
  const initiativeNames = sortInitiatives(initiativeFocus);
  const activeInitiatives = initiativeNames.filter((name) => isActiveInitiative(name, metric, initiativeFocus));

  useDeepCompareEffect(() => {
    if (initiativeFocus) {
      setInitiativesData(initiativeFocus);

      if (!isEqual(initiativesData, initiativeFocus)) {
        setInitiatives(activeInitiatives);
        setSelectedInitiatives([activeInitiatives[0]]);
        setSelectedTrends([]);
      }
    }
  }, [initiativeFocus]);

  const handleRemoveInitiative = (initiativeName: string) => {
    setInitiatives(initiatives.filter((name) => name !== initiativeName));
    setSelectedInitiatives(selectedInitiatives.filter((name) => name !== initiativeName));
    setSelectedTrends(selectedTrends.filter((name) => name !== initiativeName));
  };

  const initiativeOptions = initiativeNames.filter((name) => !initiatives.includes(name));

  return (
    <DropShadowContainer ref={downloadRef}>
      <LoadingOverlay visible={isFetching} overlayProps={{ blur: 2 }} style={{ zIndex: 200 }} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '12px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '5px' }}>
          <Pills pillText={pills} />
          <SelectContainer style={{ marginLeft: 'auto' }}>
            <SelectLabel>Value</SelectLabel>
            <Select
              label=""
              size="xs"
              style={{ width: 140 }}
              value={metric}
              data={selectData}
              onChange={(value) => setMetric(value as MetricType)}
              allowDeselect={false}
            />
          </SelectContainer>
          <div ref={exceptionRef}>
            {<DownloadPNGButton handleDownload={handleDownload} exceptionRef={exceptionRef} />}
          </div>
        </div>
        <Title>Initiative Focus</Title>
      </div>
      {initiativeFocus ? (
        <ContentContainer>
          <LegendContainer>
            <LegendScrollContainer>
              {initiatives.length > 0 ? (
                <LegendTable>
                  <thead>
                    <tr>
                      <StickyTH>Initiatives</StickyTH>
                      <StickyTH>Line</StickyTH>
                      <StickyTH>Trend</StickyTH>
                      <StickyTH></StickyTH>
                    </tr>
                    <tr style={{ height: '16px' }}></tr>
                  </thead>
                  <tbody>
                    {initiatives.map((initiativeName, index) => {
                      const color = ITEM_COLORS[index % ITEM_COLORS.length];
                      return (
                        <Fragment key={index}>
                          <tr>
                            <RowStart color={color}>{initiativeName}</RowStart>
                            <TD>
                              <Checkbox
                                color={findClosestMantineColor(color)}
                                checked={selectedInitiatives.includes(initiativeName)}
                                onChange={(event) =>
                                  handleChangeEntity(
                                    event.currentTarget.checked,
                                    initiativeName,
                                    selectedInitiatives,
                                    setSelectedInitiatives
                                  )
                                }
                                size="xs"
                                style={{ paddingBottom: '2px' }}
                              />
                            </TD>
                            <TD>
                              <Checkbox
                                color={findClosestMantineColor(color)}
                                checked={selectedTrends.includes(initiativeName)}
                                onChange={(event) =>
                                  handleChangeTrend(
                                    event.currentTarget.checked,
                                    initiativeName,
                                    selectedTrends,
                                    setSelectedTrends
                                  )
                                }
                                size="xs"
                                style={{ paddingBottom: '2px' }}
                              />
                            </TD>
                            <TD>
                              <DeleteIcon
                                src={icons.iconDelete}
                                onClick={() => handleRemoveInitiative(initiativeName)}
                                alt="delete"
                              />
                            </TD>
                          </tr>
                          <tr style={{ height: '4px' }}></tr>
                        </Fragment>
                      );
                    })}
                  </tbody>
                </LegendTable>
              ) : (
                <NoInitiativesPlaceholder>
                  {initiativeNames.length > 0
                    ? 'No initiatives selected. Please select an initiative from the dropdown below.'
                    : `No initiatives found for the selected ${activeTab.slice(0, -1)}.`}
                </NoInitiativesPlaceholder>
              )}
            </LegendScrollContainer>
            {initiativeOptions.length > 0 && (
              <DropdownContainer>
                <ComboboxSelect
                  options={initiativeOptions.map((name) => ({ value: name, label: name }))}
                  onChange={(value) => {
                    setInitiatives([...initiatives, value as string]);
                  }}
                  placeholder="Select initiative"
                />
              </DropdownContainer>
            )}
          </LegendContainer>
          <ChartContainer>
            <ProcessAnalysisChart
              initiativeCompletions={initiativeFocus}
              metric={metric}
              entitiesWithColor={matchEntitiesWithColor(selectedInitiatives, initiatives) as Entities}
              entityTrendsWithColor={matchEntitiesWithColor(selectedTrends, initiatives) as Entities}
              type={'focus'}
            />
          </ChartContainer>
        </ContentContainer>
      ) : null}
    </DropShadowContainer>
  );
}
