import { ChartData, TooltipItem } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ProcessAnalysisChartData } from '../../api/portfolio-client/portfolio-client.type';
import { simpleLinearRegression } from '../../helpers/math-helpers/math-helpers';
import { newCOLORS } from '../../styles/colors';
import { createLine } from './process-analysis-chart.helpers';
import { Entities, MetricType } from './process-analysis.type';

type ProcessAnalysisInitiativeChartProps = {
  initiativeCompletions: ProcessAnalysisChartData;
  metric: MetricType;
  entitiesWithColor: Entities;
  entityTrendsWithColor: Entities;
  type: 'completion' | 'focus';
};

export const ProcessAnalysisChart = ({
  initiativeCompletions,
  metric,
  entitiesWithColor,
  entityTrendsWithColor,
  type,
}: ProcessAnalysisInitiativeChartProps) => {
  const axesTicks = type === 'focus' ? { min: 0, max: 100, callback: (value: string | number) => `${value}` } : {};
  const options = {
    maintainAspectRatio: false,
    pointStyle: true,
    aspectRatio: 2.5,
    layout: {
      padding: {
        top: 20,
        right: 0,
      },
    },
    animation: {
      duration: 0, // Set to 0 to disable all animations
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Months',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
          callback: function (this: any, value: string | number, index: number): string {
            const label = this.getLabelForValue(index || value);
            const [month, yearLastTwoDigits] = label.toString().split(' ');
            const year = `20${yearLastTwoDigits}`;
            return `${year} ${month.toUpperCase()}`;
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        position: 'left' as const,
        title: {
          display: true,
          text: type === 'completion' ? (metric === MetricType.Tasks ? 'Tasks Completed' : 'Points Completed') : '%',
          font: {
            size: 16,
          },
        },
        ticks: {
          ...axesTicks,
          font: {
            size: 14,
          },
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        common: {
          drawTime: 'afterDraw',
        },
      },
      filler: {
        propagate: true,
        drawTime: 'beforeDatasetsDraw' as const,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: TooltipItem<'line'>) => {
            const label = `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;

            return type === 'focus' ? `${label}%` : label;
          },
        },
      },
    },
  };

  const formatData = (): ChartData<'line'> => {
    const chartData: ChartData<'line'> = {
      labels: initiativeCompletions.labels,
      datasets: [],
    };

    for (const entity in entitiesWithColor) {
      chartData.datasets.push(
        createLine(
          entity,
          Object.values(initiativeCompletions[entity]?.[metric] ?? []),
          entitiesWithColor[entity] ?? newCOLORS.black,
          false
        )
      );
    }

    for (const entity in entityTrendsWithColor) {
      const entityData = initiativeCompletions[entity]?.[metric] ?? [];
      const filteredData = Object.values(entityData).filter((value) => value !== null);
      const xAxis = Array.from({ length: filteredData.length }, (_value, index) => index);

      if (xAxis.length > 1 && filteredData.length > 1) {
        const line = simpleLinearRegression(xAxis, filteredData);
        if (line) {
          const { slope, intercept } = line;
          const trendData = xAxis.map((x) => slope * x + intercept);
          chartData.datasets.push(
            createLine(`${entity} Trend`, trendData, entityTrendsWithColor[entity] ?? newCOLORS.black, true)
          );
        }
      }
    }

    return chartData;
  };

  return <Line data={formatData()} options={options} />;
};
