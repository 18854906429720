import { Measure } from '../../../api/work-periods-client/work-periods-client.type';
import { newCOLORS } from '../../../styles/colors';

const measureMetadata = {
  [Measure.Strategy]: {
    label: 'Strategy',
    description: '% tied to an epic',
    suffix: '%',
  },
  [Measure.Complexity]: {
    label: 'Complexity',
    description: 'avg. estimate for pointed tasks',
    suffix: null,
  },
  [Measure.Quality]: {
    label: 'Quality',
    description: 'bug/debt share',
    suffix: '%',
  },
  [Measure.ScopeCreep]: {
    label: 'Scope',
    description: 'tasks +/- to plan',
    suffix: '%',
  },
  [Measure.Readiness]: {
    label: 'Readiness',
    description: 'defined tasks',
    suffix: '%',
  },
  [Measure.Independence]: {
    label: 'Independence',
    description: 'Tasks with zero dependency',
    suffix: '%',
  },
  [Measure.ReviewTime]: {
    label: 'Review Time',
    description: 'Avg. days PR is open',
    suffix: null,
  },
  [Measure.DeclinedChangeRequests]: {
    label: 'PR closed (%)',
    description: 'PR closed (%)',
    suffix: '%',
  },
};

const phaseMetadata = [
  {
    id: 'backlog',
    label: 'Ready',
    description: 'not started',
    color: newCOLORS.indigo,
  },
  {
    id: 'blocked',
    label: 'Blocked',
    description: 'held up',
    color: newCOLORS.coral,
  },
  {
    id: 'wip',
    label: 'WIP',
    description: 'started',
    color: newCOLORS.darkYellow,
  },
  {
    id: 'review',
    label: 'In Review',
    description: 'code review',
    color: newCOLORS.lightYellow,
  },
  {
    id: 'test',
    label: 'In Test',
    description: 'acceptance',
    color: newCOLORS.pink,
  },
  {
    id: 'deployable',
    label: 'To Deploy',
    description: 'staged',
    color: newCOLORS.aqua,
  },
  {
    id: 'done',
    label: 'Done',
    description: 'work finished',
    color: newCOLORS.green,
  },
];

const phaseMetadataMap = {
  ready: 'backlog',
  blocked: 'blocked',
  wip: 'wip',
  'in review': 'review',
  'in test': 'test',
  'to deploy': 'deployable',
  done: 'done',
};

export { measureMetadata, phaseMetadata, phaseMetadataMap };
