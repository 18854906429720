import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Button, Divider, Select } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useEffect } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useWorkPeriods } from '../../../api/work-periods-client/work-periods-client.hooks';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  resetLocalMessage,
  setLocalMessage,
  setWorkPeriod,
  setWorkPeriodBoardId,
  setWorkPeriods,
} from '../../../store/process-analysis-store/process-analysis-store.actions';
import { newCOLORS } from '../../../styles/colors';
import { WorkPeriodType } from '../process-analysis.type';
import { useWorkPeriodForm, workPeriodFormActions } from './scope-explorer.hooks';
import {
  getBoardIds,
  getBoardOptions,
  getWorkPeriodOptions,
  getWorkPeriodTypeOptions,
  sortWorkPeriods,
} from './work-period-form.helpers';

export function WorkPeriodForm() {
  const teams = useGlobalStore((state) => state.portfolioTeams);
  const workPeriods = useProcessAnalysisStore((state) => state.workPeriods);
  const globalBoardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);

  const { workPeriodForm, handleSubmitWorkPeriodForm } = useWorkPeriodForm();
  const localBoardId = workPeriodForm.getValues().workPeriodBoardId;
  const workPeriodType = workPeriodForm.getValues().workPeriodType;

  useDeepCompareEffect(() => {
    if (teams.length) {
      const boardIds = getBoardIds(teams);
      const isValidBoard = globalBoardId && boardIds.includes(globalBoardId);

      if (!isValidBoard || !globalBoardId) {
        setWorkPeriodBoardId(boardIds[0] || null);
        workPeriodFormActions.setFieldValue('workPeriodBoardId', boardIds[0] || null);
        workPeriodFormActions.resetDirty();
      }
    } else {
      setWorkPeriodBoardId(null);
      workPeriodFormActions.setFieldValue('workPeriodBoardId', null);
      workPeriodFormActions.resetDirty();
    }
  }, [teams]);

  const { data: workPeriodsData } = useWorkPeriods({ subproject_id: localBoardId }, { enabled: !!localBoardId });
  useEffect(() => {
    if (workPeriodsData && workPeriodsData.length === 0) {
      setWorkPeriods([]);
      setLocalMessage('There are no work periods in this board');
    } else if (workPeriodsData && workPeriodsData.length > 0) {
      const sortedWorkPeriods = sortWorkPeriods(workPeriodsData);
      setWorkPeriods(sortedWorkPeriods);
      resetLocalMessage();
    }
  }, [workPeriodsData]);

  const workPeriodOptions = getWorkPeriodOptions(workPeriods);
  useDeepCompareEffect(() => {
    const currentWorkPeriod = workPeriodForm.getValues().workPeriodId;
    const isValidWorkPeriod = workPeriodOptions.find((wp) => wp.value === currentWorkPeriod);

    if (workPeriodOptions.length > 0 && !isValidWorkPeriod) {
      setWorkPeriod(workPeriods.find((wp) => wp.id === workPeriodOptions[0].value) || null);
      workPeriodFormActions.setFieldValue('workPeriodId', workPeriodOptions[0].value);
      workPeriodFormActions.resetDirty();
    } else {
      setWorkPeriod(null);
      workPeriodFormActions.setFieldValue('workPeriodId', null);
      workPeriodFormActions.resetDirty();
    }
  }, [workPeriodOptions]);

  return (
    <form onSubmit={workPeriodForm.onSubmit(() => handleSubmitWorkPeriodForm(workPeriodForm))}>
      <Panel>
        <Select
          required
          {...workPeriodForm.getInputProps('workPeriodBoardId')}
          data={getBoardOptions(teams)}
          allowDeselect={false}
        />
        <Select
          required
          {...workPeriodForm.getInputProps('workPeriodType')}
          data={getWorkPeriodTypeOptions()}
          allowDeselect={false}
        />
        <Divider orientation="vertical" />
        {workPeriodType === WorkPeriodType.Defined && workPeriods.length > 0 && (
          <Select {...workPeriodForm.getInputProps('workPeriodId')} data={workPeriodOptions} allowDeselect={false} />
        )}
        {workPeriodType === WorkPeriodType.Custom && (
          <>
            <DatePickerInput
              required
              leftSection={<Icon icon="ion:calendar-outline" color={newCOLORS.blue} width={16} height={16} />}
              leftSectionPointerEvents="none"
              {...workPeriodForm.getInputProps('startDate')}
              maxDate={workPeriodForm.values.endDate}
              pointer
              miw="fit-content"
            />
            <DatePickerInput
              required
              leftSection={<Icon icon="ion:calendar-outline" color={newCOLORS.blue} width={16} height={16} />}
              leftSectionPointerEvents="none"
              {...workPeriodForm.getInputProps('endDate')}
              minDate={workPeriodForm.values.startDate}
              pointer
              miw="fit-content"
            />
          </>
        )}
        <Button
          disabled={!workPeriodForm.isDirty() || !workPeriodForm.isValid()}
          color={newCOLORS.darkIndigo}
          type="submit"
          miw={120}
        >
          Apply Filter
        </Button>
      </Panel>
    </form>
  );
}

const Panel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 16px;
`;
