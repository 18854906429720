import { AxiosError, AxiosResponse } from 'axios';
import { ViewByType } from '../../components/burn-flow/burn-flow-chart.type';
import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { Initiative } from '../initiative-client/initiative-client.type';
import { ProcessAnalysisChartData } from '../portfolio-client/portfolio-client.type';
import {
  HistoricalBurnsResult,
  Project,
  ProjectBoardSprints,
  ProjectMetricsDetailsEndpointDescription,
  ProjectMetricsDetailsResult,
  ProjectMetricsResponse,
  ProjectSprintsResponse,
  ProjectsResponse,
} from './projects-client.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL + '/api/projects/';

/**
 * Fetches a list of projects for the current user.
 *
 * @param orgId incase the projects need to be constrained by orgs
 * @returns ProjectsResponse[]
 */
const fetchProjects = async (orgId?: string | null): Promise<ProjectsResponse[]> => {
  try {
    let apiURL = baseURL;
    if (orgId) {
      apiURL = baseURL + `?organization_id=${orgId}`;
    }
    const response: AxiosResponse = await axiosInstance.get(apiURL);
    return response.data.projects;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while fetching projects.'));
    }
  }
};

/**
 * Fetches a given project for the current user, given a project id (gid).
 *
 * @param gid - project id
 * @returns Project
 */
const fetchProject = async (gid: string): Promise<Project> => {
  const apiURL = baseURL + `${gid}/`;
  return await axiosInstance
    .get(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response));
};

/**
 * Updates a project
 *
 * @param project
 * @returns Project
 */
const updateProject = async (project: any) => {
  const apiURL = baseURL + `${project.id}/`;
  return await axiosInstance
    .put(apiURL, project)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response));
};

/**
 * Fetches metrics/statistics about a project, given an ID.
 *
 * @param projectId - ID of the project to fetch metrics
 * @param subprojectId - ID of the subproject to fetch metrics
 * @returns ProjectMetricsResponse
 */
const fetchProjectMetrics = async (
  projectId: string,
  subprojectId?: string | null
): Promise<ProjectMetricsResponse> => {
  let apiURL = '';
  if (subprojectId) {
    apiURL = baseURL + `${projectId}/subprojects/${subprojectId}/metrics/`;
  } else {
    apiURL = baseURL + `${projectId}/metrics/`;
  }
  return await axiosInstance
    .get(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response || 'Error fetching metrics'));
};

/**
 * Method to fetch the project metric details
 *
 * @param projectId given project id
 * @param subprojectId given subproject id
 * @param endpointDescription selected metric endpoint description
 * @returns
 */
const fetchProjectMetricsDetails = async (
  projectId: string,
  subprojectId: string | null,
  endpointDescription: ProjectMetricsDetailsEndpointDescription
): Promise<ProjectMetricsDetailsResult> => {
  const apiURL = subprojectId
    ? baseURL + `${projectId}/subprojects/${subprojectId}/${endpointDescription}/`
    : baseURL + `${projectId}/metrics/${endpointDescription}/`;
  return await axiosInstance
    .get(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response || `Error fetching ${endpointDescription} details`));
};

/**
 * Method to fetch the historical burns data
 *
 * @param projectId selected project id
 * @param subprojectId selected subproject id
 * @param ViewByType selected view by type(tasks/points)
 * @returns an object of historical burns data
 */
async function fetchHistoricalBurnsData(
  projectId: string,
  subprojectId: string,
  ViewByType: ViewByType
): Promise<HistoricalBurnsResult> {
  const url = new URL(baseURL + `${projectId}/subprojects/${subprojectId}/historical-burns-band/`);
  const params = new URLSearchParams(url.search);
  params.append('burn_type', ViewByType);
  url.search = params.toString();
  return await axiosInstance
    .get(url.toString())
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response || `Error fetching historical-burns details`));
}

/**
 * Fetches historical sprint data (aka Reporting Interval) for a given project by ID
 *
 * @param projectId The ID of the project within which to fetch historical sprint data
 * @param pageNum (optional) The pagination page number of the historical sprint data to fetch. Default number of items per page is 16
 * @returns A promise of an array of ProjectSprintsResponse objects.
 */
const fetchProjectSprints = async (projectId: string, pageNum = 1): Promise<ProjectSprintsResponse> => {
  const apiURL = baseURL + `${projectId}/sprints/?page=${pageNum}`;
  return await axiosInstance
    .get(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response || 'Error fetching historical sprints'));
};

/**
 * Method to fetch the project board sprints
 *
 * @param projectId project Id
 * @returns an object of list of sprints with respect to their boards
 */
async function fetchProjectBoardSprints(projectId: string): Promise<ProjectBoardSprints> {
  const url = baseURL + `${projectId}/board-sprints/`;

  return axiosInstance
    .get(url.toString())
    .then(({ data }) => Promise.resolve(data))
    .catch((error) => Promise.reject(error.response || 'Error fetching project board sprints'));
}

/**
 * Method to fetch the initiatives for a given project
 *
 * @param projectId project id to fetch initiatives for
 * @returns a list of initiatives
 */
const getInitiatives = async (projectId: string): Promise<Initiative[]> => {
  try {
    const url = baseURL + `${projectId}/initiatives/`;
    const response: AxiosResponse = await axiosInstance.get(url);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while fetching initiative completions.'));
    }
  }
};

/**
 * Method to fetch the initiative completions for a given portfolio
 *
 * @param projectId project id
 * @param startDate start date to filter
 * @param endDate end date to filter
 * @returns a object containing initiative completions
 */
const getInitiativeCompletion = async (
  projectId: string,
  startDate: string,
  endDate: string
): Promise<ProcessAnalysisChartData> => {
  try {
    let url = baseURL + `${projectId}/initiative-completion/`;
    if (startDate) {
      url += `?start_date=${startDate}`;
    }
    if (endDate) {
      url += `&end_date=${endDate}`;
    }
    const response: AxiosResponse = await axiosInstance.get(url);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while fetching initiative completions.'));
    }
  }
};

/**
 * Method to fetch the initiative focus for a given portfolio
 *
 * @param projectId project id
 * @param startDate start date to filter
 * @param endDate end date to filter
 * @returns a object containing initiative focus
 */
const getInitiativeFocus = async (
  projectId: string,
  startDate: string,
  endDate: string
): Promise<ProcessAnalysisChartData> => {
  try {
    let url = baseURL + `${projectId}/initiative-focus/`;
    if (startDate) {
      url += `?start_date=${startDate}`;
    }
    if (endDate) {
      url += `&end_date=${endDate}`;
    }
    const response: AxiosResponse = await axiosInstance.get(url);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while fetching initiative focus.'));
    }
  }
};

export {
  fetchHistoricalBurnsData,
  fetchProject,
  fetchProjectBoardSprints,
  fetchProjectMetrics,
  fetchProjectMetricsDetails,
  fetchProjects,
  fetchProjectSprints,
  getInitiativeCompletion,
  getInitiativeFocus,
  getInitiatives,
  updateProject,
};
