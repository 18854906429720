import { ProcessAnalysisChartData } from '../../../api/portfolio-client/portfolio-client.type';
import { MetricType, Tab } from '../process-analysis.type';
import { InitiativeSectionSelectionAggregated } from './initiative-sections.type';

/**
 * Sorts the initiative headers in the given initiatives data.
 *
 * @param {ProcessAnalysisChartData | undefined} initiativesData - The ProcessAnalysisChartData to sort.
 * @returns {string[]} - A sorted list of initiative headers.
 */
const sortInitiatives = (initiativesData: ProcessAnalysisChartData | undefined): string[] => {
  return Object.keys(initiativesData || {})
    .filter((header) => header !== 'labels')
    .toSorted();
};

/**
 * Returns the default selection for the initiative sections context.
 *
 * @returns {Record<Tab, InitiativeSectionSelectionAggregated>} - An object with the default selection for the initiative sections.
 */
const getDefaultInitiativeSectionSelection = (): InitiativeSectionSelectionAggregated => {
  const selection = {
    metric: MetricType.Tasks,
    initiatives: [],
    selectedInitiatives: [],
    selectedTrends: [],
    initiativesData: null,
  };

  return {
    [Tab.Portfolios]: selection,
    [Tab.Teams]: selection,
  };
};

/**
 * Checks if an initiative is active by looking at its average value for the given metric in the given data.
 *
 * @param {string} name - The name of the initiative to check.
 * @param {MetricType} metric - The metric to check.
 * @param {ProcessAnalysisChartData | undefined} data - The ProcessAnalysisChartData to check.
 *
 * @returns {boolean} - True if the initiative is active, false otherwise.
 */
const isActiveInitiative = (name: string, metric: MetricType, data: ProcessAnalysisChartData | undefined): boolean => {
  const lastMonth = data?.[name]?.['average']?.[metric] ?? null;

  return lastMonth !== null && lastMonth !== undefined && lastMonth > 0;
};

export { getDefaultInitiativeSectionSelection, isActiveInitiative, sortInitiatives };
