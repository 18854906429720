import { styled } from '@linaria/react';
import { Button, Divider } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useDeepCompareEffect } from 'react-use';
import { icons } from '../../../assets/icons/icons';
import { BoardMultiselect } from '../../../components/multiselect/board-multiselect';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { setBoardIds } from '../../../store/process-analysis-store/process-analysis-store.actions';
import { newCOLORS } from '../../../styles/colors';
import { boardFormActions, useBoardForm } from './scope-explorer.hooks';

export const BoardForm = () => {
  const { boardForm, handleSubmitBoardForm } = useBoardForm();
  const teams = useGlobalStore((state) => state.portfolioTeams);
  const boardIds = useProcessAnalysisStore((state) => state.boardIds);

  useDeepCompareEffect(() => {
    if (!teams.length) {
      return setBoardIds([]);
    }

    const portfolioBoards = teams.filter((team) => team.subprojects.length).flatMap((team) => team.subprojects);
    const isValidSelection = boardIds.every((boardId) => portfolioBoards.some((board) => board.id === boardId));

    if (!isValidSelection || !boardIds.length) {
      const defaultBoard = portfolioBoards.find((board) => Boolean(board.id)) || null;
      setBoardIds(defaultBoard ? [defaultBoard.id] : []);
      boardFormActions.setFieldValue('boards', defaultBoard ? [defaultBoard.id] : []);
      boardFormActions.resetDirty();
    }
  }, [teams, boardIds]);

  return (
    <form onSubmit={boardForm.onSubmit(() => handleSubmitBoardForm(boardForm))}>
      <Panel>
        <BoardMultiselect
          required
          {...boardForm.getInputProps('boards')}
          onChange={(boardIds) => boardForm.setFieldValue('boards', boardIds)}
        />
        <Divider orientation="vertical" />
        <DatePickerInput
          required
          leftSection={<img src={icons.iconCalendar} width={16} height={16} />}
          leftSectionPointerEvents="none"
          {...boardForm.getInputProps('startDate')}
          maxDate={boardForm.values.endDate}
          pointer
          miw="fit-content"
        />
        <DatePickerInput
          required
          leftSection={<img src={icons.iconCalendar} width={16} height={16} />}
          leftSectionPointerEvents="none"
          {...boardForm.getInputProps('endDate')}
          minDate={boardForm.values.startDate}
          pointer
          miw="fit-content"
        />
        <Button
          disabled={!boardForm.isDirty() || !boardForm.isValid()}
          color={newCOLORS.darkIndigo}
          type="submit"
          miw={120}
        >
          Apply Filter
        </Button>
      </Panel>
    </form>
  );
};

const Panel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 16px;
`;
