import { styled } from '@linaria/react';
import { CostByInitiativeResponse } from '../../api/financials-client/financials-client.type';
import { UNCHECKED_COLOR } from '../allocation-of-costs/allocation-of-costs';
import { CostByInitiativeItem } from './cost-by-initiative-item';
import { ShowCostByInitiativeChartBar } from './cost-by-initiative.type';

type CostByInitiativeItemListProps = {
  data: CostByInitiativeResponse[];
  showCostByInitiativeChartLines: ShowCostByInitiativeChartBar[];
  updateShowChartLines: (checked: boolean, id: string, currentColor: string) => void;
};

export const CostByInitiativeItemList = ({
  data,
  showCostByInitiativeChartLines,
  updateShowChartLines,
}: CostByInitiativeItemListProps) => {
  return (
    <ScrollingList>
      {(data || []).map((initiative, index) => {
        const showData = showCostByInitiativeChartLines.find((line) => line.id === initiative.id);

        const n_months = (Object.keys(data[0]?.chart_data?.initiative) || []).length;
        const average = n_months > 0 ? initiative.cost_to_date / n_months : initiative.cost_to_date;

        return (
          <CostByInitiativeItem
            key={index}
            title={initiative.name}
            id={initiative.id}
            color={showData?.color || UNCHECKED_COLOR}
            current={initiative.cost_to_date}
            average={average}
            checked={showData?.show || false}
            disabled={false}
            updateShowChartLines={updateShowChartLines}
          />
        );
      })}
    </ScrollingList>
  );
};

const ScrollingList = styled.div`
  height: 355px;
  width: 420px;
  overflow: auto;
`;
